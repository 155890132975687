import * as JsSearch from "js-search";
import React, { Component } from "react";
import StopWords from "./StopWords";
import UrlService from "../services/global/urlService"
import { retrieveActualityContent, retrieveFaqContent, retriveContent } from "../services/searchPageContainerService"
import { ATInternetTagService } from '../services/global/ATInternetService';


class SearchPage extends Component {

  nbResultPerPage = 10;

  state = {
    faqs: [],
    otherPages: [],
    genericblocks: [],
    faqsSearch: {},
    otherPagesSearch: {},
    searchResults: [],
    isLoading: true,
    isError: false,
    searchQuery: "",
    hasSearched: false,
    nbPage: 0,
    currentPage: 0,
    searchPage: []
  }

  /**
   * React lifecycle method to build the data
   */
  componentWillMount() {
    let otherPagesToIndex = []
    let faqsToIndex =  []

    //indexation des pages
    let pages = this.props.data.jhipster.pages;
    for (let i = 0; i < pages.length; i++) {
          let page = pages[i];
          let content = "";
          if (`${process.env.GATSBY_PROD}` === "false" ||
            (`${process.env.GATSBY_PROD}` === "true")
          ) {
            for (let j = 0; j < page.blocks.length; j++) {
              content += " " + retriveContent(page.blocks[j]);
            }
            const toIndex = {
              _id: 'page_' + page.id,
              url: page.url,
              title: page.title,
              description: page.description,
              content: content
            }
        otherPagesToIndex.push(toIndex);
      }
    }
    //indexation actualitées
    let actualities = this.props.data.jhipster.actualities;
    for (let i = 0; i < actualities.length; i++) {
      let actuality = actualities[i];
      const actUrl = UrlService.addSlashToEndOfUrl(actuality.url)
      let url = `/actualite/${actUrl}`
      const toIndex = {
        _id: 'actuality_' + actuality.id,
        url: url,
        title: actuality.title,
        description: actuality.description,
        content: retrieveActualityContent(actuality)
      }
      otherPagesToIndex.push(toIndex);
    }

    //indexation FAQ
    let faqsPublished = this.props.data.jhipster.faqsPublished;
    for (let i = 0; i < faqsPublished.length; i++) {
      let faq = faqsPublished[i];
      if (faq.published) {
        const faqUrl = UrlService.addSlashToEndOfUrl(faq.url)
        let url = `/faq/${faqUrl}`
        const toIndex = {
          _id: 'faq_' + faq.id,
          url: url,
          title: faq.title,
          description: faq.description,
          content: retrieveFaqContent(faq)
        }
        faqsToIndex.push(toIndex);
      }
    }
    //liste de contenu a indexer
    this.setState({ faqs: faqsToIndex, otherPages: otherPagesToIndex })
    
    const {search} =this.props
    const {recherche} = search || {}
    if (recherche) {
      this.setState({ searchQuery: recherche })
    }
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = this.props
    const { search: prevSearch } = prevProps
    if (!!search && search !== prevSearch) {
      this.launchSearch(search.recherche);
    }
  }
  
  /**
   * React lifecycle method to build the index
   */
  async componentDidMount() {
    ATInternetTagService.sendPage({page: 'resultats_moteur',page_chapter1:'Recherche'})

    console.log("this.props.search.recherche")
    console.log(this.props.search.recherche)

    this.setState(
      {
        faqsSearch: this.rebuildIndex(this.state.faqs),
        otherPagesSearch: this.rebuildIndex(this.state.otherPages),
        isLoading: false
      },
      e => {
        if (this.props.search.recherche) {
          this.launchSearch(this.props.search.recherche);
        }
      }
    )
  }

  /*
    handleChange(event) {
      this.setState({ searchQuery: event.target.value });
      if (event.target.value === '') {
        this.setState({ searchResults: [], hasSearched: false })
      }
    }
  
    searchDataDesktop = e => {
      const { search } = this.state
      const searchQuery = this.state.searchQuery;
      const queryResult = search.search(searchQuery)
      this.setState({ searchResults: queryResult, hasSearched: true })
    }
  */



  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = (documents) => {
    const dataToSearch = new JsSearch.Search("_id")
    /**
     *  defines a indexing strategy for the data
     * more more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("_id")

    /***************************************************************************************
    * --- STOP WORD TO EXCLUDE ---
    * Liste des mots à exclure dans StopWords.init (méthode statique)
    ***************************************************************************************/
    dataToSearch.tokenizer =
      new JsSearch.StopWordsTokenizer(
        new JsSearch.SimpleTokenizer());
    StopWords.init(JsSearch);

    /***************************************************************************************
    * --- CHAMPS A FAIRE INDEXER ---
    ***************************************************************************************/
    dataToSearch.addIndex("_id")
    dataToSearch.addIndex("url") // sets the index attribute for the data
    dataToSearch.addIndex("title") // sets the index attribute for the data
    dataToSearch.addIndex("description") // sets the index attribute for the data
    dataToSearch.addIndex("content") // sets the index attribute for the data
    dataToSearch.addDocuments(documents)

    return dataToSearch
  }

  resetQuery = e => {
    this.searchQuery = '';
    this.setState({ searchQuery: '', searchResults: [], searchPage: [], hasSearched: false, nbPage:0, currentPage:0})
  }

  launchSearch(searchQuery) {
    const { toggleSearchInProgress } = this.props
    const { faqsSearch, otherPagesSearch } = this.state;
    const searchQueryNormalized = searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, "");    
    const queryResult = [...faqsSearch.search(searchQueryNormalized), ...otherPagesSearch.search(searchQueryNormalized)]
    const nbPage = Math.ceil(queryResult.length / this.nbResultPerPage);
    const searchPage = queryResult.slice(0, this.nbResultPerPage);
    this.setState({ searchResults: queryResult, hasSearched: true, searchQuery: searchQuery, nbPage: nbPage, currentPage: 0, searchPage: searchPage })
    toggleSearchInProgress()
    ATInternetTagService.sendSearchDisplayClick({ise_keyword:searchQueryNormalized,ise_page:1})
    window.dataLayer.push({
      event: "search",
      search_term: searchQuery
    });
  }

  loadPageResult(num) {
    const { searchResults } = this.state;
    if (searchResults) {
      const searchPage = searchResults.slice((num * this.nbResultPerPage), (num * this.nbResultPerPage) + this.nbResultPerPage);
      this.setState({ searchPage: searchPage, currentPage: num });
      ATInternetTagService.sendSearchDisplayClick({ise_keyword:this.state.searchQuery,ise_page:num+1})
    }
    window.scrollTo(0, 0)
  }

  previousPage() {
    const { currentPage } = this.state;
    this.loadPageResult(currentPage - 1);
    window.scrollTo(0, 0)
  }

  nextPage() {
    const { currentPage } = this.state;
    this.loadPageResult(currentPage + 1);
    window.scrollTo(0, 0)
  }

  //Mobile
  searchData = e => {
    e.preventDefault();
    this.launchSearch(e.target.value);
  }

  handleSubmit = e => {
    e.preventDefault()
  }

  handleSubmitMobile = e => {
    e.preventDefault()
    this.launchSearch(this.state.searchQuery);
    this.refs.inputSearchMobile.blur();
    window.scrollTo(0, 350);
  }

  //Desktop
  handleChange(event) {
    this.setState({ searchQuery: event.target.value });
    if (event.target.value === '') {
      this.resetQuery();
    }
  }

  searchDataDesktop = e => {
    console.log("sfsdffsdf");
    console.log(this.state.searchQuery);
    window.dataLayer.push({
      event: "search",
      search_term: this.state.searchQuery
    });

    this.launchSearch(this.state.searchQuery);


  }

  render() {
    const { searchResults, searchQuery, hasSearched, searchPage, nbPage, currentPage } = this.state;

    return (
      <>
        <section className="pageHeader callback">
          <div>
            <h3 className="pageHeaderTitle">Recherche</h3>
          </div>
          <div>
            <p className="pageHeaderSubtitle">Rechercher du contenu sur le site de la MGC</p>
          </div>
          <form onSubmit={this.handleSubmit} className="faqArticleResultFormDesktop">
            <input type="text" value={searchQuery} onChange={this.handleChange} placeholder="Saisissez un mot-clé ou une question" />
            <button onClick={this.searchDataDesktop} className="buttonSearch">Rechercher</button>
          </form>

          <form onSubmit={this.handleSubmitMobile} className="faqArticleResultFormMobile">
            <button className="fa fa-search"></button>
            <input id="formFaqSearch"  ref="inputSearchMobile" name="faq-search" type="text" placeholder="Rechercher" value={searchQuery} onChange={this.handleChange} />
            <button type="reset" className="icon-close" onClick={this.resetQuery}></button>
          </form>
          <p className="pageHeaderSubtitle">Exemples de recherche : « <a href="/recherchePage?recherche=remboursement"><u>remboursement</u></a> », « <a href="/recherchePage?recherche=adhésion"><u>adhésion</u></a> », « <a href="/recherchePage?recherche=garantie"><u>garantie</u></a> », « <a href="/recherchePage?recherche=contrat"><u>contrat</u></a> » ...</p>
        </section>

        <div className={"container"}>
          <div>
            <div style={{ margin: "0 auto" }}>

              <div className="container">
                <div hidden={!hasSearched} className="nbresultats">
                  Nombre de résultats : {searchQuery !== '' ? searchResults.length : 0}
                </div>
                <div className="row" >
                  {searchPage.map((item,loopIndex) => {
                    if (searchQuery !== '') {
                      return (
                        <div className="col-12" key={`row_${item._id}`}>
                          <div className="actualitesArticles">
                            <h4 className="actualitesArticlesTitle"><a href={item.url}>{item.title}</a></h4>
                            <p className="actualitesArticlesPublication">{item.description}</p>
                            <a className="actualitesArticlesLink" href={item.url} 
                              onClick={() => ATInternetTagService.sendSearchClick({ise_keyword:this.state.searchQuery,
                                ise_page:(this.state.currentPage+1),
                                ise_click_rank:(loopIndex+1)})}> 
                              Lire la suite</a>
                          </div>
                          <hr className="actualitesHr" />
                        </div>
                      )
                    } else {
                      return;
                    }
                  })}
                </div>
                <form onSubmit={this.handleSubmit}  >
                  <div className="recherche">
                    <ul className="recherchePagination">
                      <li  key="prev" className="recherchePaginationItem">
                        {currentPage !== 0 ? (
                          <a onClick={(e) => this.previousPage(e)}>←</a>
                        ) : (<></>)}
                      </li>
                      {Array.from({ length: nbPage }, (_, i) => (
                        i === currentPage ?
                          (
                            <li key={i} className="recherchePaginationItem">
                              <a className="active" onClick={(e) => this.loadPageResult(i, e)}>{i + 1}</a>
                            </li>
                          ) : (
                            <li key={i} className="recherchePaginationItem">
                              <a onClick={(e) => this.loadPageResult(i, e)}>{i + 1}</a>
                            </li>
                          )
                      ))}
                      <li  key="next" className="recherchePaginationItem">
                        {(currentPage !== (nbPage - 1) && nbPage !== 0) ? (
                          <a onClick={(e) => this.nextPage(e)}>→</a>
                        ) : (<></>)}
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SearchPage;
