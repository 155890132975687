/**************************************************************************************
 * recherche contenu fonction type de block
 * --- A COMPLETER PAR CHAQUE TYPE DE CONTENU ---
 **************************************************************************************/
export const retriveContent = block => {
  switch (block.__typename) {
    case "jhipster_SimpleBlockDTO":
      return retrieveSimpleBlock(block)
    case "jhipster_FourElementPinkBlockDTO":
      return retrieveFourElementPinkBlock(block)
    case "jhipster_ThreeBlockNumberDTO":
      return retrieveThreeBlockNumber(block)
    case "jhipster_CibleBlockDTO":
      return retrieveCibleBlock(block)
    case "jhipster_ColorBlockDTO":
      return retrieveColorBlock(block)
    case "jhipster_FourTabBlockDTO":
      return retrieveFourTabBlock(block)
    case "jhipster_FormulaHeaderBlockDTO":
      return retrieveFormulaHeaderBlock(block)
    case "jhipster_HeaderFormulaBlockDTO":
      return retrieveHeaderFormulaBlock(block)
    case "jhipster_GammeFormulasBlockDTO":
      return retrieveGammeFormulasBlock(block)
    case "jhipster_CarouselFaqBlockDTO":
      return retrieveCarouselFaqBlock(block)
    default:
      break
  }
  return ""
}

/**************************************************************************************
 * recuperation type de block : simpleblock
 * --- A CREER PAR CHAQUE TYPE DE CONTENU---
 ***************************************************************************************/
export const retrieveActualityContent = actuality => {
  const { content, title, description } = actuality
  return ` ${content || ""} ${title || ""} ${description || ""}`
}

/**************************************************************************************
 * recuperation type de block : simpleblock
 * --- A CREER PAR CHAQUE TYPE DE CONTENU---
 ***************************************************************************************/
export const retrieveFaqContent = faq => {
  const { content, title, description } = faq
  return ` ${title || ""} ${description || ""} ${content || ""}`
}

/**************************************************************************************
 * recuperation type de block : simpleblock
 * --- A CREER PAR CHAQUE TYPE DE CONTENU---
 ***************************************************************************************/
const retrieveSimpleBlock = block => {
  return ` ${block.content || ""}`
}

const retrieveFourElementPinkBlock = block => {
  return (
    " " +
    (block.title ? block.title : "") +
    " " +
    (block.subtitle1 ? block.subtitle1 : "") +
    " " +
    (block.text1 ? block.text1 : "") +
    " " +
    (block.subtitle2 ? block.subtitle2 : "") +
    " " +
    (block.text2 ? block.text2 : "") +
    " " +
    (block.subtitle3 ? block.subtitle3 : "") +
    " " +
    (block.text3 ? block.text3 : "") +
    " " +
    (block.subtitle4 ? block.subtitle4 : "") +
    " " +
    (block.text4 ? block.text4 : "")
  )
}

const retrieveThreeBlockNumber = block => {
  return (
    " " +
    (block.title ? block.title : "") +
    " " +
    (block.subtitle ? block.subtitle : "") +
    " " +
    (block.titleBlock1 ? block.titleBlock1 : "") +
    " " +
    (block.subtitleBlock1 ? block.subtitleBlock1 : "") +
    " " +
    (block.titleBlock2 ? block.titleBlock2 : "") +
    " " +
    (block.subtitleBlock2 ? block.subtitleBlock2 : "") +
    " " +
    (block.titleBlock3 ? block.titleBlock3 : "") +
    " " +
    (block.subtitleBlock3 ? block.subtitleBlock3 : "")
  )
}

const retrieveCibleBlock = block => {
  return (
    " " +
    (block.titleLeft ? block.titleLeft : "") +
    " " +
    (block.textLeft ? block.textLeft : "") +
    " " +
    (block.titleRight ? block.titleRight : "") +
    " " +
    (block.textRight ? block.textRight : "")
  )
}
const retrieveColorBlock = block => {
  return ` ${block.content || ""}`
}

const retrieveCarouselFaqBlock = block => {
  return ""
}

const retrieveGammeFormulasBlock = block => {
  const { title, subTitle } = block
  return ` ${title || ""} ${subTitle || ""}`
}

const retrieveHeaderFormulaBlock = block => {
  const { title, subTitle, description } = block
  return ` ${title || ""} ${subTitle || ""} ${description}`
}

const retrieveFormulaHeaderBlock = block => {
  const { title, titleRight, textRight } = block
  return ` ${title || ""} ${titleRight || ""} ${textRight || ""}`
}

const retrieveFourTabBlock = block => {
  const {
    titleTab1,
    textTab1,
    titleTab2,
    textTab2,
    titleTab3,
    textTab3,
    titleTab4,
    textTab4,
    textTab5,
    titleTab5,
    textTab6,
    titleTab6,
    nombreBlocksInitialser
  } = block
  return (
    " " +
    (titleTab1 || "") +
    " " +
    (textTab1 || "") +
    " " +
    (titleTab2 || "") +
    " " +
    (textTab2 || "") +
    " " +
    (titleTab3 || "") +
    " " +
    (textTab3 || "") +
    " " +
    (titleTab4 || "") +
    " " +
    (textTab4 || "") +
    " " +
    (titleTab5 || "") +
    " " +
    (textTab5 || "") +
    " " +
    (titleTab6 || "") +
    " " +
    (textTab6 || "") +
    (nombreBlocksInitialser || "")
  )
}
