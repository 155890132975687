import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SearchPage from "../components/SearchPageContainer"
import withLocation from "../components/withLocation"
import BannerQuestionDesktop from "../images/cartouche1telOct2022.png"
import {
  GlobalDispatchContext,
  } from "../context/globalContextProvider"
const IndexPage = ({ data, search }) => {
  const metaRobots = [
    {
      name: "robots",
      content: "noindex",
    },
  ]

  return (
    <Layout
      titleSeo="Recherche"
      description={
        "Remboursement, adhésion, garantie, contrat… Effectuez une recherche sur le site de la Mutuelle MGC pour trouver une réponse en quelques clics."
      }
      meta={metaRobots}
    >
      <section className="pageHeader">
        <div className="pageHeaderContainer">
          <nav>
            <ul className="breadcrumb">
              <li className="breadcrumbItem">
                <a
                  href="/"
                  className="breadcrumbItemLink breadcrumbItemLinkHome"
                >
                  <i className="icon-mgc" />
                </a>
              </li>
              <li className="breadcrumbItem">
                <span className="breadcrumbItemLink breadcrumbItemLinkMiddle">
                  Recherche
                </span>
              </li>
            </ul>
          </nav>
          <div className="ciblePubDesktop">
            <img src={BannerQuestionDesktop} alt="" />
          </div>
        </div>
      </section>
      <GlobalDispatchContext.Consumer>
        {dispatch => (
          <SearchPage data={data} search={search} toggleSearchInProgress={() => dispatch({type: "TOGGLE_SEARCH"})}/>
        )}
      </GlobalDispatchContext.Consumer>

    </Layout>
  )
}

export const pageQuery = graphql`
  fragment media on jhipster_AbstractMediaDTO {
    id
    icon {
      className
      id
      lib
    }
    image {
      id
      name
      path
    }
  }

  fragment simpleBlock on jhipster_SimpleBlockDTO {
    id
    ordre
    content
  }

  fragment twoButtonQuotationBlock on jhipster_TwoButtonQuotationBlockDTO {
    id
    ordre
  }

  fragment faq on jhipster_FaqDTO {
    id
    title
    titleSEO
    keywords
    tracker
    description
    content
    published
    category
    url
  }

  fragment fourElementPinkBlock on jhipster_FourElementPinkBlockDTO {
    id
    ordre
    title
    subtitle1
    text1
    subtitle2
    text2
    subtitle3
    text3
    subtitle4
    text4
  }

  fragment threeBlockNumber on jhipster_ThreeBlockNumberDTO {
    id
    ordre
    title
    subtitle
    titleBlock1
    subtitleBlock1
    titleBlock2
    subtitleBlock2
    titleBlock3
    subtitleBlock3
  }

  fragment cibleBlock on jhipster_CibleBlockDTO {
    id
    ordre
    color
    titleLeft
    textLeft
    titleRight
    textRight
    knowMore
  }

  fragment colorBlock on jhipster_ColorBlockDTO {
    id
    ordre
    content
  }

  fragment formule on jhipster_FormuleDTO {
    id
    libelle
    ordre
    codeCouleur
    lien
    prixMensuel
    niveauSoins
    niveauHospi
    niveauOptique
    niveauDentaire
    niveauMedecineDouce
    niveauAidesAuditives  
  }

  fragment formulaHeaderBlock on jhipster_FormulaHeaderBlockDTO {
    id
    ordre
    title
    titleRight
    textRight
    buttonRight
    knowMore
    mgcgestionFormulaId
    formule {
      ...formule
    }
    gamme {
      ...gamme
    }   
  }

  fragment gamme on jhipster_GammeDTO {
    id
    libelle
    code
    niveauAidesAuditivesDisponible
    formules {
      ...formule
    }
  }

  
  fragment RazSimulatorBlock on jhipster_RazSimulatorBlockDTO {
    id
    ordre
    mgcgestionFormulaId
    mgcgestionGammeId
  }
  
  fragment GammeFormulasBlock on jhipster_GammeFormulasBlockDTO {
    id
    ordre
    title
    subTitle
    gammeMgcgestionId
    buttonText
    mediaPdf {
      ...media
    }
    offerImage {
      ...media
    }
    offerURL
    offerConditionsText
    offerConditionsLink
    gamme {
      ...gamme
    }
  }

  fragment fourTabBlock on jhipster_FourTabBlockDTO {
    id
    ordre
    textTab1
    titleTab1
    textTab2
    titleTab2
    textTab3
    titleTab3
    textTab4
    titleTab4
    mgcgestionFormuleId
    textTab5
    titleTab5
    textTab6
    titleTab6
    nombreBlocksInitialser
  }

  fragment carouselFaqBlock on jhipster_CarouselFaqBlockDTO {
    id
    faq0 {
      ...faq
    }
    faq1 {
      ...faq
    }
    faq2 {
      ...faq
    }
    faq3 {
      ...faq
    }
    faq4 {
      ...faq
    }
    faq5 {
      ...faq
    }
    faq6 {
      ...faq
    }
    faq7 {
      ...faq
    }
    faq8 {
      ...faq
    }
  }

  fragment headerFormulaBlock on jhipster_HeaderFormulaBlockDTO {
    id
    ordre
    title
    subTitle
    description
    textConditionsLink
    conditionsLink
  }

  fragment ariane on jhipster_ArianeDTO {
    url
    title
  }

  fragment page on jhipster_PageDTO {
    id
    title
    url
    template
    titleSEO
    keywords
    description
    tracker
    ariane {
      ...ariane
    }
    blocks {
      ...simpleBlock
      __typename
      ...fourElementPinkBlock
      __typename
      ...threeBlockNumber
      __typename
      ...cibleBlock
      __typename
      ...colorBlock
      __typename
      ...fourTabBlock
      __typename
      ...formulaHeaderBlock
      __typename
      ...carouselFaqBlock
      __typename
      ...twoButtonQuotationBlock
      __typename
      ...headerFormulaBlock
      __typename
      ...GammeFormulasBlock
      __typename
      ...RazSimulatorBlock
      __typename
    }
  }

  fragment actuality on jhipster_ActualityDTO {
    id
    title
    titleSEO
    description
    content
    media {
      ...media
    }
    keywords
    tracker
    formattedPublished
    url
  }

  {
    jhipster {
      pages {
        ...page
      }
      faqsPublished {
        ...faq
      }
      actualities {
        ...actuality
      }
    }
  }
`

export default withLocation(IndexPage)
